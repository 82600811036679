.delivery-costs-modal-button {
    display: flex;

    button {
        @include mix-theme-primary-color-props('color');
        font-size: .75rem;
        line-height: 1rem;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
        &.total-button {
            margin-left: auto;
        }
    }
}
