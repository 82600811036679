.radio-button {
    display: flex;
    align-items: center;

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        border: 1px solid $input-border-color;
        border-radius: 100%;
        transition: 0.3s;
        transition-property: border-color;

        &:hover {
            cursor: pointer;
            @include mix-primary-theme-border(1px, solid);
        }

        &:checked {
            @include mix-primary-theme-border(1px, solid);

            &::before {
                content: "";
                display: block;
                height: 10px;
                width: 10px;
                border-radius: 100%;
                @include mix-theme-primary-color-props("background-color");
            }
        }
    }

    label {
        font-size: 0.875rem;
        line-height: 1.25;
        margin-left: 1rem;
        color: #000;
        cursor: pointer;
    }
}
