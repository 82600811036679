.arrow-link {
    @include mix-theme-like-a();
    @include themer($theme-hansreitzel) {
        text-decoration: none;
    }
        
    a {
        @include mix-theme-arrow-right-after();
        text-decoration: none;
        white-space: nowrap;
        color: inherit;
    }
}