@use "sass:math";

.main-footer__contact {
    position: relative;

    a {
        color: inherit;
    }
}

.main-footer__contact__inner {
    max-width: $max-width;
    margin: auto;
    overflow: auto;
    padding: $gutter;
    display: grid;
    gap: $gutter;

    @include mix-content-min-width($breakpoint-w-dog) {
        grid-template-columns: 2fr;
    }

    @include mix-content-min-width(1100px) {
        display: flex;
        justify-content: space-between;
    }
    @include mix-content-min-width($max-width + $gutter) {
        padding: 50px 0;
    }
}

.main-footer__contact__column {
    @include mix-content-min-width(1100px) {
        flex: none;
        font-size: 16px;
    }
}

.footer__contact__item {
    display: flex;
    @include mix-content-min-width(1100px) {
        font-size: 16px;
    }
}

.footer__contact__item__icon {
    position: relative;
    float: left;
    text-align: center;
    margin-right: 0.8em;

    @include mix-content-max-width(1170px) {
        min-width: math.div(50, 16) * 1em;
    }
    @include mix-content-min-width(1170px) {
        width: auto;
        text-align: left;
        height: 100%;
    }

    svg {
        width: auto;
        max-width: math.div(50, 16) * 0.65em;
        height: math.div(50, 16) * 0.65em;
        stroke: currentColor;

        @include mix-content-min-width($breakpoint-w-cat) {
            max-width: math.div(40, 16) * 0.65em;
            height: math.div(40, 16) * 0.65em;
        }
        @include mix-content-min-width($breakpoint-w-dog) {
            max-width: math.div(50, 16) * 0.65em;
            height: math.div(50, 16) * 0.65em;
        }
        @include mix-content-min-width(1170px) {
            max-width: math.div(40, 16) * 0.65em;
            height: math.div(40, 16) * 0.65em;
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
            max-width: math.div(50, 16) * 0.65em;
            height: math.div(50, 16) * 0.65em;
        }

        @include themer($theme-hansreitzel) {
            stroke: $color-hr-red;
        }
    }
}

.footer__contact__item__header {
    line-height: 0.9em;
    font-size: 1rem;
    font-weight: 100;
    margin-bottom: 0.15em;

    @include themer($theme-hansreitzel) {
        color: $color-hr-black;
        font-family: $header-font-family;
    }
    a {
        color: inherit;
    }
}

.footer__contact__item__subtext {
    font-size: 0.75em;
    line-height: 1.2;
    @include themer($theme-gyldendal, $theme-munksgaard) {
        opacity: 0.6;
    }
}
