.profile-banner {
    display: flex;

    position: relative;
    min-height: 14rem;
    border-radius: $border-radius;
    @include themer($theme-gyldendal) {
        background-color: $color-gu-fade-10;
    }
    @include themer($theme-munksgaard) {
        background-color: $profile-banner-mg-backround-color;
    }
    @include themer($theme-hansreitzel) {
        background-color: $color-hr-beige;
    }

    .content-row {
        margin-top: auto;
        margin-bottom: 2rem;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    h4 {
        z-index: 10;
        left: 2rem;
        bottom: 2rem;
        @include mix-theme-like-h4;
        @include mix-theme-primary-color-props("color");
        @include themer($theme-gyldendal) {
            text-transform: uppercase;
        }
    }

    svg {
        height: auto;
        width: 57%;
        max-width: 775px;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    @include mix-content-max-width($breakpoint-w-dog) {
        min-height: auto;
        background: none;
        svg {
            display: none;
        }

        h4 {
            position: static;
            @include mix-theme-like-h5;
            @include mix-theme-primary-color-props("color");
        }
    }
}
