.newsletter-banner {
  color: #fff;
  padding: $gutter 0;

  > .content-row {
    @include mix-content-max-width($breakpoint-w-cat) {
      padding: 0 20px;
    }
  }
  @include mix-content-min-width($breakpoint-w-lion) {
    padding: $gutter * 2 0;
  }
  @include mix-content-min-width($breakpoint-w-rhino) {
    padding: $gutter * 3 0;
  }

  @include themer($theme-gyldendal) {
    background-color: $color-gu-dark-grey;
  }
  @include themer($theme-munksgaard) {
    background-color: $color-mg-blue;
  }
  @include themer($theme-hansreitzel) {
    background-color: $color-hr-red;
  }

  @include mix-form-invert();

  .form-terms {
    margin-top: 1.3em;
    line-height: 1.3em;
  }
  .form-terms__text {
    font-size: 0.8em;
  }
}

.newsletter-banner__trumpet {
  text-transform: uppercase;
  font-size: 0.9em;
  opacity: 0.7;
  @include themer($theme-gyldendal) {
    font-weight: $fontweight-gu-bold;
  }
  @include themer($theme-munksgaard) {
    font-weight: $fontweight-mg-bold;
  }
  @include themer($theme-hansreitzel) {
    font-weight: $fontweight-hr-din-bold;
  }
}

.newsletter-banner__content-wrapper {
  margin-bottom: 1rem;
}

.newsletter-banner__header {
  @include mix-theme-like-h2();

  @include themer($theme-munksgaard) {
    font-size: 35px;
  }
  @include themer($theme-hansreitzel) {
    font-size: 35px;
  }
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  max-width: 600px;
}

.newsletter-banner__description {
  ul {
    padding-left: 1rem;
  }
}

.newsletter-banner__form {
  position: relative;
  z-index: 1;
  transition: opacity 0.4s;

  .required-star {
    color: #fff;
  }
}

.newsletter-banner__form-wrapper {
  position: relative;
}

.newsletter-banner__form__terms {
  a {
    @include themer($theme-hansreitzel) {
      color: #fff !important;
    }
  }
  @include mix-content-max-width($breakpoint-w-dog) {
    .checkbox {
      margin-bottom: 10px;
    }
  }
}

.newsletter-banner__submit-button {
  @include mix-button(#fff, $color-gu-dark-grey);
  @include mix-button-is-slim();

  @include themer($theme-munksgaard) {
    @include mix-button(#fff, $color-mg-blue-dusty);
  }
  @include themer($theme-hansreitzel) {
    @include mix-button(#fff, $color-hr-blue);
  }

  margin-top: 1.3em;
  float: right;
  clear: right;
  min-width: 150px;

  @include mix-content-max-width($breakpoint-w-dog) {
    float: left;
    clear: left;
    width: 100%;
  }
}

.newsletter-banner__recaptcha {
  float: right;
  margin-top: 1.3rem;

  @include mix-content-max-width($breakpoint-w-dog) {
    float: left;
  }
}