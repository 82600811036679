.radio-button-group {
    fieldset {
        & > div {
            margin-top: 1rem;
            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}

.radio-button-group__title {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.33;
    color: $dark-grey;
    margin-bottom: 1rem;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}
