.author-archive {
    ul {
        list-style: none;
    }
}

.author-archive__intro {
    @include mix-grid-section();
    margin-bottom: $gutter;
}
.author-archive__intro__header {
    @include mix-theme-like-h2();
    margin-bottom: 15px;
}
.author-archive__intro__description {
    @include mix-content-min-width($breakpoint-w-dog) {
        width: 80%;
    }

    a {
        @include mix-theme-like-a();
    }
}

.author-archive__content-wrapper {
    @include mix-grid-section();
    padding: $gutter;
    margin-bottom: $gutter * 2;
    position: relative;
}

// ALPHABET MENU
.author-archive__alphabet-menu {
    position: absolute;
    top: 0;
    right: 0;
    padding: $gutter-small;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 100px);
    z-index: 10;

    @include mix-content-min-width($breakpoint-w-cat) {
        padding: $gutter $gutter-small;
        height: calc(100vh - 50px);
    }
    @media (min-width: $breakpoint-navigation) {
        flex-direction: row;
        height: auto;
        min-height: 65px;
        left: 0;
        padding: $gutter;
    }

    .isMenuGloballySticky & {
        position: fixed;
        top: $navigation-header-height + 15px;

        @media (min-width: $breakpoint-w-dog) {
            top: $navigation-header-height + 5px;
        }
        @media (min-width: $breakpoint-navigation) {
            background: white;
            left: $navigation-width;
            right: 0;
            width: 100%;
            max-width: calc(100% - 300px);
            height: auto;
            padding: 15px $gutter;
            margin: 0 auto;
            overflow-y: hidden;
        }
        @include mix-content-min-width($breakpoint-w-elephant) {
            max-width: $max-width;
        }
    }

    .isMenuBottomAligned & {
        top: auto;
        bottom: 0;
    }
}

.author-archive__alphabet-menu__item {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    @media (max-width: $breakpoint-navigation) {
        width: $gutter;
        height: 1px;
    }
    @media (min-width: $breakpoint-navigation) {
        height: 30px;
        width: 1px;
    }

    a {
        @include mix-theme-like-h3();
        font-size: 1.5vh;
        transition: transform 0.5s;
        flex: 0 0 $gutter;
        position: relative;

        span {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @media (min-width: $breakpoint-navigation) {
            font-size: 20px;
            border-bottom: 2px solid transparent;
        }
        &:hover {
            //GU
            @include themer($theme-gyldendal) {
                @include mix-subtheme-primary-color-props("color");
            }
            //munk
            @include themer($theme-munksgaard) {
                color: $color-mg-green-dark;
            }
            //hans
            @include themer($theme-hansreitzel) {
                @include mix-theme-primary-color-props("color");
            }
        }
    }

    &.isActive a {
        @media (max-width: $breakpoint-navigation) {
            transform: scale(1.8);
        }

        //GU
        @include themer($theme-gyldendal) {
            @include mix-subtheme-primary-color-props("border-color");
        }
        //munk
        @include themer($theme-munksgaard) {
            color: $color-mg-green-dark;
            border-color: 2px solid $color-mg-green-dark;
        }
        //hans
        @include themer($theme-hansreitzel) {
            @include mix-theme-primary-color-props("color", "border-color");
        }
    }
    &.isDisabled a {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }
}

.author-archive__list-container {
    @media (min-width: $breakpoint-navigation) {
        padding-top: 60px;
    }
}

.author-archive__list-section {
    position: relative;
    margin-bottom: $gutter-small;

    &:last-child {
        margin-bottom: 0;
    }

    &.isEmpty {
        //   display: none;
    }
}
.author-archive__list-section__header {
    @include mix-theme-like-h2();
    .isEmpty & {
        color: lighten($color-gu-fade-25, 25);
        margin-bottom: 0.6em;
        display: inline-block;
    }
}

.author-archive__list-section__list {
    max-height: 160px;
    overflow: hidden;
    position: relative;
    padding-bottom: 0;

    .canOpen:not(.isOpen) & {
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 60px;
            background: rgba(255, 255, 255, 0);
            background: linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
            background: -webkit-linear-gradient(to top, #fff 50%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .isOpen & {
        max-height: none;
    }
}

.author-archive__list__item:last-child {
    padding-bottom: 40px;

    .author-archive__list-section:last-child & {
        padding-bottom: 0;
    }
}

.author-archive__list-section__view-more-btn {
    @include reset__button("true");
    position: absolute;
    bottom: 0;
    padding: 0.5em 5em;
    transform: translateY(-50%);
    transition: transform 0.3s;
}
.author-archive__list__item a {
    @include mix-theme-like-h3();
    @include mix-theme-paragraph-color;
    font-size: 20px;
    font-weight: normal;
}
