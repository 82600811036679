.create-profile {
    h1 {
        @include mix-theme-like-h1;
    }
    h4 {
        @include mix-theme-like-h4;
    }

    h5 {
        @include mix-theme-like-h5;
    }

    p {
        font-size: 1rem;
        line-height: 1.5;
        margin-top: 0.5rem;
    }
}

.create-profile__container {
    max-width: 1024px;
}

.create-profile__header {
    margin-bottom: 1rem;
    @include themer($theme-gyldendal) {
        @include mix-theme-like-h2;
        margin-bottom: 1.5rem;
    }
    @include themer($theme-hansreitzel) {
        @include mix-theme-like-h1;
    }
    @include themer($theme-munksgaard) {
        @include mix-theme-like-h1;
    }
}

.create-profile__subheader {
    margin-bottom: rem(8);
    @include themer($theme-gyldendal) {
        @include mix-theme-like-h5;
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        @include mix-theme-like-h4;
    }
    @include themer($theme-munksgaard) {
        @include mix-theme-like-h4;
    }
}

.create-profile__masterdata {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem 1rem;
    margin-top: 2rem;

    @include themer($theme-gyldendal) {
        margin-top: 1rem;
    }

    @include mix-content-min-width($breakpoint-w-cat) {
        grid-template-columns: 1fr 1fr;
    }
}

.create-profile__is-educator,
.create-profile__purchase-relation {
    input {
        margin: 1em 0.5em 1em 0;
        height: 1.5rem;
        width: 1.5rem;
        border-color: #bdc6cd;
    }
    input[type="radio"]:checked {
        background-color: $navigation-bg-color; /* Change the color of the dot when checked */
    }
    input[type="radio"] {
        accent-color: $navigation-bg-color;
    }
    &--gu {
        input[type="radio"] {
            // Harcoded on purpose, $color-gu-orange does not work for accent-color
            accent-color: #e87315;
        }
    }
}

.create-profile__learning-materials {
    margin-top: 1rem;
}

.create-profile__institution,
.create-profile__newsletter,
.create-profile__submit,
.create-profile__is-educator,
.create-profile__purchase-relation {
    margin-top: 3rem;
}

.create-profile__learning-materials {
    .create-profile__learning-materials-checkbox {
        margin-top: 1.25rem;
    }
}

.create-profile__institution {
    .create-profile__institution-inputs {
        margin-top: 1.5rem;
    }
}

.create-profile__submit {
    button {
        @include mix-button-is-slim;
    }

    .gdpr-text {
        margin-top: 1rem;
        font-size: 0.75rem;
        line-height: 1.33;
        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}
