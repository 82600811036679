.async-select {
    position: relative;
    display: flex;
    flex-direction: column;

    & > div {
        width: 100%;
    }

    &--optionSelected {
        input {
            &::-webkit-input-placeholder {
                color: #000;
            }
        }
    }
}

.async-select__icon {
    display: flex;

    &--clear {
        color: black;
    }
}

.async-select__options {
    display: none;
    position: absolute;
    padding: 0.5rem;
    border-radius: $border-radius;
    background-color: #fff;
    top: 2.75rem;
    z-index: 1000;
    max-height: 20rem;
    overflow: scroll;

    &--active {
        display: flex;
        flex-direction: column;
    }

    @include mix-primary-theme-border(1px, solid);
}

.async-select__option {
    font-family: inherit;
    text-align: left;
    border-radius: $border-radius;
    border: none;
    background: none;
    padding: 0.625rem 0.5rem;
    margin-top: 0.25rem;
    &:first-child {
        margin-top: 0;
    }

    &:hover,
    &--selected {
        cursor: pointer;
        transition: 0.3s;
        transition-property: background-color, color, font-weight;
        @include themer($theme-gyldendal) {
            font-weight: $fontweight-gu-medium;
        }
        @include themer($theme-hansreitzel) {
            font-weight: $fontweight-hr-din-bold;
        }
        @include themer($theme-munksgaard) {
            font-weight: $fontweight-mg-bold;
        }
        @include mix-theme-primary-background-color-tint(90);
        @include mix-theme-primary-color-props("color");
    }
}

.async-select__empty-result {
    padding: 0.625rem 0.5rem;
}

.async-select__selected-option {
    margin-top: rem(8);
    padding: rem(16);
    display: flex;
    gap: 1rem;
    // justify-content: space-between;
    background-color: $color-gu-fade-20;
    @include mix-theme-primary-background-color-tint(91%);
    border-radius: $border-radius;
    font-size: rem(14);
    span {
        margin-right: auto;
    }
}

.async-select__remove-selected-option {
    @include mix-theme-link-color;
    font-size: rem(12);
}
