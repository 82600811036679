$small: 0.8rem;
$normal: 1rem;
// 0.6 = 9.6
// 0.8 = 12.8
// 1rem = 16px
// 1.5 = 24

@mixin mix-form-invert() {
    @include mix-form-color(#fff);
}

@mixin mix-form-color($color: #fff) {
    .text-input__input {
        border-bottom: 1px solid $color;
        color: $color;
        &:focus,
        &.dirty {
            & ~ .text-input__label {
                color: $color;
            }
        }
    }
    .text-input__label {
        color: $color;
    }
    .text-input__required {
        color: $color;
        opacity: 0.5;
    }
}

.background {
    background-color: $color-gu-dark-grey;
    padding: 10px;
    color: #fff;

    @include mix-form-invert();

    @include themer($theme-munksgaard) {
        background-color: $color-mg-blue-dusty;
        padding: 10px;
        color: tint(#fff, 50);
    }
    @include themer($theme-hansreitzel) {
        background-color: #cee1e9; // No variable????
        padding: 10px;
        color: #000;
    }
}

.text-input {
    position: relative;
    width: 100%;
    display: inline-block;
    margin-bottom: 0.5em;
}

.textarea-field textarea, //for reduxforms
.text-input input, //for reduxforms
.text-input__input {
    position: relative;
    z-index: 4;
    width: 100%;
    height: 32px;
    margin-top: 1rem;
    font-size: $normal;
    background-color: rgba(0, 0, 0, 0.001);
    border: none;
    border-bottom: 1px solid $color-gu-fade-23;
    color: $color-gu-dark-grey;
    border-radius: 0;
    font-family: inherit;

    ::-webkit-input-placeholder {
        /* WebKit browsers */
        opacity: 0;
    }
    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        opacity: 0;
    }
    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        opacity: 0;
    }
    :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        opacity: 0;
    }

    @include themer($theme-hansreitzel) {
        border-bottom: 1px solid $color-hr-red;
        color: #000;
    }

    &:focus {
        border-bottom-width: 2px;
    }

    // Overwrite browser default styling
    &:invalid,
    &:focus {
        outline: none;
        box-shadow: none;
    }

    // LABEL ON FOCUS OR WHEN THE INPUT IS NOT EMPTY
    &:focus,
    &.dirty {
        & ~ .text-input__label {
            opacity: 1;
            color: rgba(0, 0, 0, 0.5);
            transform: translate(0, -1.3em) scale(0.8);
            padding: 0;
        }
    }

    // ERROR
    .submitted & &.invalid,
    &.error,
    &.invalid {
        // Get dynamic .invalid class from logic
        border-bottom-color: $color-error-fade;

        & ~ .text-input__error-msg,
        & ~ .error {
            // handling multiline error-msg when narrow input
            height: auto;
            bottom: 0;
            transform: translateY(100%);
        }
    }

    // REQUIRED
    &.required {
        padding-right: 3.7rem;

        & ~ .text-input__required {
            display: inline-block;
        }
    }

    &:disabled {
        color: rgba(0, 0, 0, 0.4);
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        font-weight: 300;
        cursor: not-allowed;

        & ~ .text-input__label {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}

.textarea-field {
    textarea {
        //for reduxforms
        border: 1px solid $color-gu-fade-23;

        @include themer($theme-hansreitzel) {
            border: 1px solid $color-hr-red;
        }
    }
    .text-input__label {
        padding-left: 5px;
    }
}

.textarea-field__label,
.text-input label, //for reduxforms
.text-input__label {
    z-index: 1;
    position: absolute;
    top: 1.6em;
    left: 0;
    opacity: 0.6;
    display: inline-block;
    font-size: $normal;
    font-weight: 100;
    color: $color-gu-dark-grey;
    line-height: 1em;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    transform-origin: 0 0;
    transition: all 0.2s ease-out;

    @include themer($theme-hansreitzel) {
        color: #000;
    }
}

label.error {
    top: 100%;
    opacity: 1;
    line-height: 1.5em;
    bottom: auto;
    height: auto;
}

.text-input__label__star,
.required-star {
    color: $color-gu-red;
}

.text-input__required {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    font-size: $small;
    font-weight: 100;
    line-height: 5;
    display: none;
    color: rgba(0, 0, 0, 0.5);

    @include themer($theme-hansreitzel) {
        color: rgba(0, 0, 0, 0.5);
    }
}

.field-error, //redux forms
input.error ~ .error,
.text-input__error-msg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    padding: 0 0.6rem;
    font-weight: 100;
    height: 0;
    overflow: hidden;
    z-index: 20;
    transition: height 0.2s ease-out, bottom 0.2s ease-out;
    color: #fff;
    background-color: $color-field-error;
}

.field-error {
    height: auto;
    overflow: auto;
    display: block;
    bottom: auto;
    top: 100%;
    line-height: 1.5em;
    width: auto;
    padding-right: 0.5em;
}

//textarea-field (redux)

.textarea-field {
    padding-top: 0.3em;
    font-size: 16px;
    position: relative;
    display: block;
    width: 100%;
    line-height: 0;
    margin-bottom: 0.5em;
    textarea {
        width: 100%;
        min-height: 4em;
    }
}
