@use "sass:math";

.hero-banner {
    position: relative;
}

.hero-banner__picture-wrapper {
    position: relative;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

.hero-banner__content {
    padding: $gutter $gutter-small;
    width: 100%;
    max-width: $max-width;
    margin: 0 auto;

    @include themer($theme-gyldendal) {
        padding: math.div($gutter, 2) $gutter-small;

        @media (min-width: $breakpoint-w-cat) {
            font-size: 12px;
            padding: math.div($gutter, 2) $gutter;
        }
        @media (min-width: $breakpoint-w-lion) {
            font-size: 16px;
        }
    }
    @media (min-width: $breakpoint-w-cat) {
        position: absolute;
        padding: $gutter;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include themer($theme-gyldendal) {
            text-align: center;
            padding-top: 0;
        }
    }
}

.hero-banner__shape {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 3;

    svg {
        margin: 0;
        padding: 0;
        width: 100%;
        fill: #fff;
        position: absolute;
        height: 5vw;
        bottom: -6px;

        @media (min-width: $breakpoint-navigation) {
            height: 4vw;
        }
    }
}

.hero-banner__trumpet {
    @include mix-theme-trumpet();

    @include themer($theme-gyldendal, $theme-munksgaard) {
        @media (min-width: $breakpoint-w-cat) {
            color: #fff;
        }
    }
}

.hero-banner__title {
    //GU
    @include themer($theme-gyldendal) {
        font-size: 24px;
        margin: 0.4em 0;

        @include mix-content-max-width($breakpoint-w-cat) {
            @include mix-theme-like-h2();
        }
        @include mix-content-min-width($breakpoint-w-cat) {
            @include mix-gu-header-styling();
            font-weight: $fontweight-gu-light;
            font-size: 30px;
            margin: 0.3em auto;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            @include mix-theme-like-h1();
            font-size: 50px;
            max-width: 840px;
        }
    }
    //munk
    @include themer($theme-munksgaard) {
        @include mix-theme-like-h2();
        position: relative;
        padding-left: 1em;
        margin: 0.3em 0;
        line-height: 1.3em;

        &:before {
            content: "";
            position: absolute;
            top: 0.25em;
            bottom: 0.25em;
            left: 0;
            width: 2px;
            background-color: $color-mg-green-dark;
        }
        @include mix-content-min-width($breakpoint-w-cat) {
            color: #fff;
            padding-left: 0.6em;
            margin: 0.2em 0;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            @include mix-theme-like-h1();
            color: #fff;
            width: 50%;
        }
    }
    //hans
    @include themer($theme-hansreitzel) {
        @include mix-theme-like-h2();
        margin: 0.2em 0 0.6em;
        line-height: 1.3em;

        @include mix-content-min-width($breakpoint-w-cat) {
            color: #fff;
            margin: 0.1em 0 0.4em;
        }
        @include mix-content-min-width($breakpoint-w-lion) {
            @include mix-theme-like-h1();
            color: #fff;
            width: 50%;
        }
    }
}

.hero-banner__subtext {
    margin-bottom: 1em;

    @include themer($theme-gyldendal, $theme-munksgaard) {
        @media (min-width: $breakpoint-w-cat) {
            color: #fff;
        }
    }
    @include themer($theme-hansreitzel) {
        color: $color-hr-red;
    }

    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        height: 1px;
        margin-right: 0.4em;
        margin-top: -0.2em;

        @include themer($theme-hansreitzel) {
            background-color: $color-hr-red;
        }
        @include themer($theme-gyldendal, $theme-munksgaard) {
            background-color: $color-gu-dark-grey;

            @media (min-width: $breakpoint-w-cat) {
                background-color: #fff;
            }
        }
    }
}

.hero-banner__cta a {
    @include themer($theme-gyldendal) {
        @include mix-button();
    }
    @include themer($theme-hansreitzel) {
        @include mix-button($color-hr-red, #fff);
    }
    @include themer($theme-munksgaard) {
        @include mix-button($color-mg-green-dark, $color-mg-blue);
    }
    @include mix-button-is-slim();
}
