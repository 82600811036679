// Gyldendal Uddannelse
$black: #000;
$white: #fff;

$lightgrey: #f5f5f5;
$dark-grey: #262626;
$dark-grey-op10: rgba($dark-grey, 0.1);

// Primary
// NOTE: These colors are potentially used in the UI, since we are giving the ColorClasses to the Editor i.e. for landingpage modules.
// See all exposed colors in _color-class-themer.scss
$color-gu-dark-grey: #555e67;
$color-gu-orange: #ef7615; // Orange - Action color
$color-gu-orange-light: #fc923c;
$color-gu-green: #69a400; // Grundskole
$color-gu-blue-light: #5b9dba; // Gymnasier
$color-gu-blue-dusty: #98a7b3;
$color-gu-pink: #a9467c; // Voksenuddannelse
$color-gu-red: #c91611; // Ordbøger
$color-gu-purple: #4338a1; // Erhvervsuddannelser

// Fades
$color-gu-fade-00: #fafafa; // Full width background
$color-gu-fade-10: #f1f2f3; // Box background
$color-gu-fade-15: #eeeff0;
$color-gu-fade-20: #e6e6e6; // Box border
$color-gu-fade-23: #878d93;
$color-gu-fade-25: #697179;
$color-gu-fade-30: #3c4349; // Menu bg - not active
$color-gu-fade-40: #32373d; // Used in menu

// General
$color-error: #a2282e;
$color-error-fade: tint($color-error, 25);
$color-field-error: $color-error;
$color-theme-primary: $color-gu-dark-grey; // See themes in /_themer.scss
$color-action: $color-gu-orange;

$navigation-bg-color: #f2f2f2;
$navigation-text-color: $color-theme-primary;
$navigation-menu-text-color: $color-theme-primary;
$navigation-menu-background-color: $white;

$navigation-search-background-color: $white;
$navigation-search-input-background-color: $white;
$navigation-search-text-color: $color-theme-primary;

$footer-main-background-color: #f2f2f2;
$footer-main-text-color: $color-gu-dark-grey;
$footer-blocklinks-background-color: rgba($color-gu-blue-dusty, 0.25);
$footer-blocklinks-text-color: $color-gu-dark-grey;

$input-border-color: #bdc6cd;

$unic-userinfo-card-background-color: #f2f2f2;
/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}

//New Color System

$color-grey-100: #f2f2f2;
