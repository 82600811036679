// Munksgaard

// --------------- COLORS ---------------
// Primary
// NOTE: These colors are potentially used in the UI, since we are giving the ColorClasses to the Editor i.e. for landingpage modules.
// See all exposed colors in _color-class-themer.scss
$color-mg-blue: #102f5d; // MG Blue
$color-mg-green: #d9da3f; // Green - Action color
$color-mg-darkgreen: #9dcb87; // Dark Green - Action color;
$color-mg-green-dark: #9b9c2c;
$color-mg-blue-dusty: #526987; // Dusty blue
$color-mg-blue-dusty-light: #92a6b9; // Dusty blue light
$color-mg-blue-light: #d2e3ea; // Light blue menu bg
$color-mg-petroleum-blue: #027675;

// Fades
$color-mg-fade-10: #0c2346; // Menu inactive
$color-mg-fade-30: #fbfbfb; // Box background
$color-mg-fade-40: #cfd5df; // Box border

$munksgaard-pattern: "data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2284.5%22%20height%3D%2286.5%22%20viewBox%3D%220%200%2084.5%2086.5%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%2C%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20none%3B%0A%20%20%20%20%20%20%20%20stroke%3A%20%23fff%3B%0A%20%20%20%20%20%20%20%20stroke-linecap%3A%20round%3B%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0A%20%20%20%20%20%20%7D%0A%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20stroke-width%3A%201px%3B%0A%20%20%20%20%20%20%7D%0A%0A%20%20%20%20%20%20.cls-2%20%7B%0A%20%20%20%20%20%20%20%20stroke-width%3A%201.2px%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C/style%3E%0A%20%20%3C/defs%3E%0A%20%20%3Cpath%20vector-effect%3D%22non-scaling-stroke%22%20class%3D%22cls-1%22%20d%3D%22M584%2C2670h83v85%22%20transform%3D%22translate%28-583%20-2669.5%29%22/%3E%0A%20%20%3Cpath%20vector-effect%3D%22non-scaling-stroke%22%20class%3D%22cls-2%22%20d%3D%22M666%2C2754l-82-83%22%20transform%3D%22translate%28-583%20-2669.5%29%22/%3E%0A%3C/svg%3E%0A";

$navigation-bg-color: $color-mg-blue;
$navigation-text-color: $white;
$navigation-menu-text-color: $color-mg-blue-dusty;
$navigation-menu-background-color: $white;

$navigation-search-background-color: $color-mg-blue-light;
$navigation-search-input-background-color: $white;
$navigation-search-text-color: $white;

$footer-main-background-color: $color-mg-blue-dusty;
$footer-main-text-color: $white;
$footer-blocklinks-background-color: $color-mg-blue;
$footer-blocklinks-text-color: $white;

$profile-banner-mg-backround-color: #f2f8fb;

//General
$color-primary: $color-mg-blue;
$color-cta: $color-mg-petroleum-blue;

// --------------- FONTS ---------------
//// Font weights MU
//$fontweight-mg-light: 300;
//$fontweight-mg-regular: 400;
//$fontweight-mg-semibold: 600;
//$fontweight-mg-bold: 700;
//
////fonts
//$body-font-family: $font-family-proxima;
//$header-font-family: $body-font-family;
//$body-font-weight: $fontweight-mg-regular;
