.checkbox{ //put on label element
    cursor: pointer;
    display: inline-block;
    padding-left: 2em;
    position: relative;
    &.checkbox--block{
        display: block;
        margin: 1em 0;
    }
    min-width: $gutter;
    min-height: $gutter;

    &[disabled]{
        opacity: .65;
        pointer-events: none;
        cursor: default;
    }
}


.checkbox__input{
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    &.error:not(:checked) ~ .checkbox__indicator {
        border: 2px solid $color-error-fade;
        background-color: $color-field-error;
    }
}


.checkbox__indicator{
    display: block;
    position: absolute;
    top: -0.05em;
    left: 0;
    border: 1px solid tint($color-gu-dark-grey, 80);
    width: 24px;
    height: 24px;
    background: $white;
    border-radius: 5px;

    @include themer($theme-hansreitzel, $theme-munksgaard) {
        border-radius: 0;
    }

    &:before,
    &:after{
        content: "";
        display: block;
        position: absolute;
        left: 38.5%;
        top: 65%;
        height: 2px;
        background-color: $color-gu-orange;
        opacity: 0;
        transition: all 100ms ease;
        will-change:transform;

        @include themer($theme-gyldendal) {
            @include mix-subtheme-primary-color-props('background-color');
        }

        @include themer($theme-hansreitzel) {
            background-color: $color-hr-red;
        }

        @include themer($theme-munksgaard) {
            background-color: $color-mg-green-dark;
        }
    }
    &:after{
        width: 12px;
        transform-origin: 0 0;
        transform: rotate(-50deg);
    }
    &:before{
        width: 5px;
        transform-origin: 0 0;
        transform: rotate(45deg) translate(-100%, -100%);
    }

    .checkbox__input:focus ~ &{
        box-shadow: 0 0 5px $color-gu-orange;
    }
}

.checkbox__input:checked ~ .checkbox__indicator{
    &:before,
    &:after{
        opacity: 1;
    }
}

.checkbox--partly-checked .checkbox__indicator {

    &:before,
    &:after {
        opacity: 1;
        top:44%;
        left:25%;
        width:5px;
        height:2px;
        transform: rotate(0deg) translate(0%, 0%);
    }

    &:after {
        left:50%;
    }
}

.form-terms{
    display: inline-block;
    padding-left: 2em;
    position: relative;
    .checkbox{
        cursor: pointer;
        position: absolute;
        left: 0;
        z-index: 20;
        min-width: $gutter;
        min-height: $gutter;
    }
}

.form-terms__text {
    > *:first-child {
        display: inline;
    }
    .required-star {
        margin-left: 5px;
    }
}

