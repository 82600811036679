html {
    @include font-smoothing();
}

body {
    @include mix-theme-paragraph-color;
    overflow-x: hidden;
    font-size:$global-font-size;
    line-height: $global-line-height;
    font-family: $body-font-family;
    font-style: $body-font-style;
    font-weight:$body-font-weight;
}

a {
    text-decoration: none;
}

small{
    font-size: .85em;
}

.arrow-icon--right-after{
    @include mix-arrow-right-after();
}
.arrow-icon--right-before {
    @include mix-arrow-right-before();
}

.link-button {
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    cursor: pointer;
    @include mix-theme-like-a;
}