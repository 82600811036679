// Font weights GU
$fontweight-gu-light: 300;
$fontweight-gu-book: 400;
$fontweight-gu-medium: 500;
$fontweight-gu-bold: 700;
$fontweight-gu-black: 800;

// Font families GU
$font-family-gotham :  "Gotham A", "Gotham B", sans-serif;

// Theme settings
$global-font-size:16px;
$global-line-height:1.5;

$body-font-family: $font-family-gotham;
$body-font-style: normal;
$body-font-weight: $fontweight-gu-book;
$header-font-family: $body-font-family;



// --------------- HR ---------------
// Font weights
$fontweight-hr-regular: 400;
$fontweight-hr-medium: 500;
$fontweight-hr-semibold: 600;
$fontweight-hr-din-normal: normal;
$fontweight-hr-din-bold: bold;

// Font families
$font-family-DIN : "FFDINWebPro", serif;
$font-family-minion:  "minion-pro", serif;


@if $site == 'hansreitzel' {
    $body-font-family: $font-family-DIN;
    $body-font-weight: $fontweight-hr-din-normal;
    $header-font-family: $font-family-minion;
}



// --------------- Munksgaard ---------------
// Font weights
$fontweight-mg-light: 300;
$fontweight-mg-regular: 400;
$fontweight-mg-semibold: 600;
$fontweight-mg-bold: 700;

// Font families
$font-family-proxima: "proxima-nova", sans-serif;

@if $site == 'munksgaard' {
    //fonts
    $body-font-family: $font-family-proxima;
    $header-font-family: $body-font-family;
    $body-font-weight: $fontweight-mg-regular;
}
