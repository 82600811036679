.image-spot-list {
    @include mix-grid-section;
    width: 100%;
}

.image-spot-list__spots {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include mix-content-min-width($breakpoint-w-dog) {
        flex-wrap: nowrap;
    }
}

.image-spot-list__image-spot {
    width: 100%;
    margin: 0.5rem 0;
    display: block;

    @include mix-content-max-width($breakpoint-w-dog) {
        width: 100%;
    }
    @include mix-content-min-width($breakpoint-w-dog) {
        margin: 0 0.5rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.image-spot-list__image-spot-image {
    display: block;
    position: absolute;
    border-radius: $border-radius;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;

    &--hasText:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background: #262626;
        opacity: 0.2;
        @include themer($theme-gyldendal) {
            border-radius: $border-radius;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.image-spot-list__image-spot-aspect-ratio {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    display: block;
}

.image-spot-list__image-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    text-align: center;
    width: 95%;
    display: block;
}

.image-spot-list__image-spot-header-container {
    position: absolute;
    top: 5%;
    left: 0%;
    z-index: 1;

    &-button {
        @include reset__button("true");
        color: inherit;
        font-size: inherit;
        font-family: $body-font-family;
        font-weight: bold;
        text-align: left;
        width: 80%;
        display: block;
        font-size: 1.1rem;
        padding: 0.4rem 0.7rem;
        min-width: 60%;
        width: auto;
        @include mix-content-min-width($breakpoint-w-dog) {
            font-size: 0.6rem;
            padding: 0.2rem 0.5rem;
        }

        @include mix-content-min-width($breakpoint-w-lion) {
            font-size: 0.8rem;
        }

        @include mix-content-min-width($breakpoint-w-horse) {
            font-size: 1rem;
        }

        @include mix-content-min-width($breakpoint-w-elephant) {
            font-size: 1.1rem;
        }
    }
}

.image-spot-list__image-spot-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    text-align: center;
    width: 80%;
    display: block;
    color: white;
    font-size: 1.4rem;
    font-weight: 500;

    @include mix-content-min-width($breakpoint-w-dog) {
        font-size: 0.8rem;
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        font-size: 1rem;
    }

    @include mix-content-min-width($breakpoint-w-horse) {
        font-size: 1.2rem;
    }

    @include mix-content-min-width($breakpoint-w-elephant) {
        font-size: 1.6rem;
    }

    &.Middle {
        top: 50%;
    }
    &.Bottom {
        top: 70%;
    }
    &.Top {
        top: 30%;
    }

    &-button {
        @include reset__button("true");
        font-size: inherit;
        font-family: $header-font-family;
        color: inherit;
        font-weight: inherit;
    }
}

.image-spot-list__image-spot-button-container {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    text-align: center;
    width: 85%;
    display: block;
    color: white;

    button {
        font-size: inherit;
        font-family: $body-font-family;
        color: inherit;
    }

    @include mix-content-min-width($breakpoint-w-dog) {
        button {
            transform: scale(0.6);
        }
    }

    @include mix-content-min-width($breakpoint-w-lion) {
        button {
            transform: scale(0.8);
        }
    }

    @include mix-content-min-width($breakpoint-w-horse) {
        button {
            transform: scale(1);
        }
    }

    @include mix-content-min-width($breakpoint-w-rhino) {
        bottom: 5%;
    }
}

.image-spot-list__image-spot-trumpet {
    font-size: 1.125rem;
    line-height: 1.5;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    letter-spacing: 1px;
    margin-bottom: 1rem;
}

.image-spot-list__image-spot-text {
    @include mix-theme-like-header();
    font-size: 2rem;
    line-height: 1.25;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @include themer($theme-gyldendal) {
        line-height: 1;
        font-weight: $fontweight-gu-medium;
    }

    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-semibold;
    }

    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-semibold;
    }
}
