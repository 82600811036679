//Themes
$theme-gyldendal: "gyldendal";
$theme-hansreitzel: "hansreitzel";
$theme-munksgaard: "munksgaard";
$theme-editor: "editor"; //sitecore-editor

//Sub themes
$subtheme-grundskolen: "subtheme-grundskolen";
$subtheme-gymnasier: "subtheme-gymnasier";
$subtheme-voksenuddannelse: "subtheme-voksenuddannelse";
$subtheme-ordboeger: "subtheme-ordboeger";
$subtheme-erhvervsuddannelse: "subtheme-erhvervsuddannelse";

//Grid
$max-width: 1440px; //1380px;
$gutter: 25px;
$gutter-small: 10px;

//Header
$header-height: 50px;
$navigation-header-height: 80px;

$border-radius: 4px;
