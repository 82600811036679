.system-list__header-container {
    @include mix-grid-section();

    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.system-list__header {
    @include mix-theme-like-h2();
}

.system-list__go-to-link a {
    @include mix-theme-like-a();
    @include mix-theme-arrow-right-after();
    text-decoration: none;
    white-space: nowrap;
}

// SORTING
// TODO: This is copied from product-sort.scss -> make one sort styling without specifying product-/system-
.system-list__options {
    @include mix-grid-section();
    @include mix-clearfix-table;
    clear: both;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    @include mix-theme-paragraph-color;
    margin-bottom: $gutter;

    @include mix-content-min-width($breakpoint-w-cat) {
        margin-bottom: $gutter + 10;
    }
}

.system-list__option {
    float: left;

    &:last-child {
        float: right;
    }
}

.system-list__amount {
    margin: 4px 0 0;
}

.systems-sort__headline,
.systems-sort__options {
    display: block;

    .systems-sort--inline & {
        display: inline-block;
    }
}

.systems-sort__headline {
    margin: 0 6px 0 0;
}

.systems-sort__options-wrapper {
    display: inline-block;
}

.systems-sort__option {
    @include reset__button("true");
    @include mix-tags-hollow--secondary;
    margin: 0 3px 0;
    white-space: normal;
    cursor: pointer;
    text-align: left;
}

.systems-sort__option--active {
    @include mix-tags--primary;
}

// THE LIST
.system-list-wrapper {
    @include mix-grid-section();
    position: relative;
    min-height: 320px; // for initial loader
}

.system-list {
    margin: -10px;
    @include mix-clearfix-table;
}
.system-list-btn-load-more {
    margin-top: $gutter-small;

    @include mix-content-min-width($breakpoint-w-cat) {
        margin-top: $gutter;
    }
}

.system-item {
    list-style: none;
    padding: 10px;
    float: left;
    color: #fff;
    width: 100%;

    @include mix-content-min-width($breakpoint-w-cat) {
        width: 50%;
    }
    @include mix-content-min-width($breakpoint-w-lion) {
        width: 33.3333333%;
    }
    @include mix-content-min-width($breakpoint-w-rhino) {
        width: 25%;
    }

    a {
        color: white;
    }
}

.system-item__inner {
    position: relative;
    height: 300px;
}

.system-item__tags {
    position: absolute;
    top: -10px;
    right: 10px;

    .system-item__tag {
        display: inline-block;
        float: left;
        text-transform: uppercase;
        line-height: 20px;
        font-size: 12px;
        padding: 0 5px;

        &.subject {
            @include mix-tags--secondary;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &.type {
            @include mix-tags--primary;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        // Don't show on sub-systems
        .sub-systems & {
            display: none;
        }
    }
}

.system-item__image {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;

    img {
        width: auto;
        min-width: 100%;
    }
}

.system-item__meta-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-gu-dark-grey;
    padding-bottom: 45px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    // No room for sub-systems if it's a list of sub-systems
    .sub-systems & {
        padding-bottom: 0;
    }
}

.system-item__meta-container__system-title {
    position: relative;
    padding: 20px;
    font-weight: $fontweight-gu-bold;
    white-space: nowrap;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50px;
        background: rgba(85, 94, 103, 0);
        background: linear-gradient(to left, $color-gu-dark-grey 40%, rgba(85, 94, 103, 0) 100%);
        background: -webkit-linear-gradient(to left, $color-gu-dark-grey 40%, rgba(85, 94, 103, 0) 100%);
    }
}

.system-item__meta-container__system-subtitle {
    font-weight: 100;
    font-size: 14px;
    opacity: 0.6;
}

.system-item__meta-container__subsystem-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    // TODO: will not be in dom when real data
    .sub-systems & {
        display: none;
    }
}

.system-item__meta-container__subsystem-header {
    font-weight: $fontweight-gu-bold;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    line-height: 45px;
    position: relative;

    .system-icon {
        width: 50px;
        height: 45px;
        position: absolute;
        top: 0;
        left: 0;
        svg {
            stroke-width: 1px;
            stroke: #fff;
            fill: #fff;
        }
    }
}

.system-item__meta-container__subsystems {
    display: none;
}
