.facets-header {
    margin-bottom: 16px;
    line-height: 25px;
}

.facets-header__headline {
    @include mix-tags-label;
}

.facets {
    width: 100%;
}

.facet {
    @include mix-boxes__background();
    @include mix-boxes__border();
    position: relative;
    margin-bottom: rem(20);
    overflow: hidden;

    @include themer($theme-gyldendal) {
        border-radius: 5px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.facet__header {
    position: relative;
    @include mix-boxes__border-color();
    border-bottom-width: 1px;
    border-style: solid;
    transition: border-color 300ms ease;

    button {
        @include reset__button();
        @include mix-theme-headline-color;
        position: relative;
        display: block;
        width: 100%;
        height: rem(50);
        padding: 0 rem(20);
        text-align: left;
        font-size: 1rem;
        font-weight: 700;
        font-family: $body-font-family;
        &:focus {
            outline-offset: -3px;
            outline: solid black;
        }
    }
}

.facet__icon {
    @include reset__button("true");
    display: block;
    right: rem(20);
    width: rem(10);
    height: rem(10);
    border-top: 1px solid $color-gu-dark-grey;
    border-left: 1px solid $color-gu-dark-grey;
    cursor: pointer;

    @include themer($theme-hansreitzel) {
        border-color: $color-hr-blue;
    }

    @include themer($theme-munksgaard) {
        border-color: $color-mg-blue;
    }
}

.facet__header__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateY(rem(3)) rotate(45deg);
    pointer-events: none;
}

.facet__lists {
    overflow: hidden;
    height: auto;
    padding: 0;
}

.facet__list {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1;
}

.facet__list__item {
    position: relative;
    transition: background 200ms ease;

    .facet__icon {
        @include reset__button("true");
        position: absolute;
        top: 20px;
        right: 20px;
        transform: translateY(0) rotate(135deg);
        bottom: initial;
    }
}

.facet__list__item__checkbox {
    padding: 14px 20px;

    @include themer($theme-hansreitzel) {
        color: $color-hr-blue;
    }

    @include themer($theme-munksgaard) {
        color: #000;
    }
}

.facet__list__item__amount {
    margin-left: 0.25em;
    color: tint($color-gu-dark-grey, 50);

    @include mix-content-max-width($breakpoint-w-lion) {
        //display: none;
    }

    @include themer($theme-hansreitzel) {
        color: tint($color-hr-blue, 50);
    }

    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty-light;
    }
}

.facet__list__item__loading {
    margin-left: 0.25em;

    @include mix-content-max-width($breakpoint-w-lion) {
        display: none;
    }
}

.facet__list .facet__list {
    @include mix-boxes__background--inverted;

    .facet__list__item__checkbox {
        color: $white;

        @include themer($theme-munksgaard) {
            color: $black;
        }
    }
}

.facet__list__item--active {
    @include mix-boxes__background--active;

    .facet__icon {
        transform: translateY(-3px) rotate(225deg);
    }
}

.facet__reveal {
    border-top: 1px solid $color-gu-fade-20;

    button {
        @include reset__button();
        display: block;
        width: 100%;
        height: 50px;
        padding: 0 20px;
        font-size: 1rem;
        font-weight: 400;
        font-family: $body-font-family;
        color: $color-gu-orange;
        transition: opacity 300ms ease;

        &:focus {
            outline-offset: -3px;
            outline: solid black;
        }

        &:disabled {
            opacity: 0.3;
        }
        @include mix-subtheme-primary-color-props("color");

        @include themer($theme-hansreitzel) {
            color: $color-hr-red;
        }

        @include themer($theme-munksgaard) {
            color: $color-mg-blue;
        }
    }
}

.facet--isCollapsed {
    .facet__header {
        border-bottom-color: transparent;
    }

    .facet__header__icon {
        transform: translateY(rem(-3)) rotate(225deg);
    }
}
