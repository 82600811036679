@use "sass:math";

.my-orders {
    margin-bottom: $gutter * 2;
}

.my-orders__header-container {
    @include mix-grid-section();
    @include mix-content-min-width($breakpoint-w-dog) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.my-orders__header {
    @include mix-theme-like-h2();
}
.my-orders__go-to-link a {
    @include mix-theme-like-a();
    @include mix-theme-arrow-right-after();
    text-decoration: none;
    white-space: nowrap;
}

.my-orders__error-message {
    @include mix-grid-section();
    color: $color-error;
}

.my-orders__list-container {
    position: relative; // for load-overlay
    min-height: 175px;
    background: $color-gu-fade-00;
    border-top: 1px solid $color-gu-fade-20;
    border-bottom: 1px solid $color-gu-fade-20;
    padding: $gutter 0;

    @include mix-content-min-width($breakpoint-w-lion) {
        min-height: 225px;
        padding: $gutter 0 $gutter * 2 0;
    }
}
.my-orders__list-wrapper {
    @include mix-grid-section();
}
.my-orders-list {
    @include mix-grid-section();
    border-collapse: separate;
    list-style: none;
    margin-bottom: $gutter;
}

.my-orders__list-header {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 600;

    .mobile-list & {
        display: none;
    }
}
.my-orders__item {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid $color-gu-fade-20;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid $color-gu-fade-20;
    }

    @include themer($theme-gyldendal) {
        &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .mobile-list & {
        display: block;
        border-radius: 5px;
        border: 1px solid $color-gu-fade-20;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}
.my-orders__item__title {
    @include mix-theme-like-h3();
}
.my-orders__item__subtitle {
    @include mix-theme-label-text();
}
.my-orders__item__col {
    padding: 20px 0 20px 20px;
    width: 25%;

    &:last-child {
        padding-right: 20px;
        text-align: right;
    }
}
.my-orders__item__meta {
    .mobile-list & {
        width: 100%;
        padding: 20px;
    }
}

.my-orders__item__status {
    .mobile-list & {
        width: auto;
        float: left;
        padding: 0 20px 0 0;
        line-height: 1.5em;
    }
    a {
        @include mix-theme-like-a();
    }
}
.my-orders__item__status-tag {
    @include mix-tags--primary();
}

.my-orders__item__price-wrapper {
    border-top: 1px solid $color-gu-fade-20;
    background: $color-gu-fade-15;
    padding: 20px;
    overflow: auto;
}
.my-orders__item__price {
    text-align: right;
    white-space: nowrap;
    @include mix-theme-like-h3();
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }

    .mobile-list & {
        width: auto;
        float: right;
        padding: 0;
    }
}

.my-orders-btn-load-more {
    margin-top: $gutter;
}

.my-orders__empty {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    max-width: math.div($max-width, 2);
    transform: translateY(-50%);
    text-align: center;
}
