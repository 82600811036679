.eventbuizz-modal {
    .modal-container {
        @include mix-content-max-width($breakpoint-w-lion) {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: none;
            transform: none;

            .modal-container__content {
                height: 100%;
                max-height: none;
            }
        }
    }
}

.eventbuizz-modal-content {
    border-radius: $border-radius;
    background-color: $white;

    @include mix-content-max-width($breakpoint-w-lion) {
        border-radius: 0;
    }
}

.eventbuizz-modal-header {
    border-radius: $border-radius $border-radius 0 0;
    padding: 2rem 2.5rem;
    height: 80px;
    background-color: $navigation-bg-color;
    color: $dark-grey;
    display: flex;

    @include mix-content-max-width($breakpoint-w-lion) {
        border-radius: 0;
        height: 136px;
    }

    .eventbuizz-modal-header__title {
        @include mix-theme-like-h5;
        text-transform: uppercase;
        color: inherit;
        margin: auto 0;
    }

    .eventbuizz-modal-header__date {
        height: 80px;
        width: 80px;
        position: absolute;
        right: 230px;
        top: 40px;
        border-radius: $border-radius;
        z-index: 1;
        background-color: $color-gu-orange-light;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;

        @include mix-content-max-width($breakpoint-w-rhino) {
            right: 55px;
        }

        @include mix-content-max-width($breakpoint-w-lion) {
            top: 96px;
            right: 24px;
        }

        .date-number {
            font-size: 2rem;
            line-height: 2rem;
            font-weight: $fontweight-gu-medium;
        }

        .date-month {
            font-size: 0.875rem;
            line-height: 1rem;
        }
    }

    .close-action {
        position: absolute;
        z-index: 10;
        top: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
    }
}

.eventbuizz-modal-body {
    padding: 2rem 2.5rem;

    @include mix-content-max-width($breakpoint-w-cat) {
        padding: 2rem 1.5rem;
    }

    .eventbuizz-modal-body__tags {
        @include mix-theme-like-h5;
        text-transform: uppercase;
        color: $color-gu-blue-dusty;
    }
}

.eventbuizz-modal-body-grid {
    display: grid;
    grid-template-areas: "event-title . ." "event separator event-info";
    grid-template-columns: 2.55fr auto 1fr;
    grid-gap: 2.5rem;

    @include mix-content-max-width($breakpoint-w-rhino) {
        margin-top: 1.25rem;
        grid-template-columns: 1fr;
        grid-template-areas:
            "event-title"
            "event-info"
            "event";

        .eventbuizz-modal-body-grid-separator {
            display: none;
        }
    }

    h5 {
        @include mix-theme-like-h5;
        text-transform: uppercase;
        font-weight: $fontweight-gu-bold;
    }
}

.eventbuizz-modal-body__event-title {
    @include mix-theme-like-h2;
    grid-area: event-title;
    margin-top: 0.5rem;

    @include mix-content-max-width($breakpoint-w-cat) {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: $fontweight-gu-bold;
    }
}

.eventbuizz-modal-body-grid-event {
    grid-area: event;

    .eventbuizz-modal-body-grid-event__program,
    .eventbuizz-modal-body-grid-event__speakers {
        margin: 1rem 0;

        &.eventbuizz-modal-body-grid-event__speakers {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 1rem;

            @include mix-content-max-width($breakpoint-w-cat) {
                grid-template-columns: 1fr 1fr;
            }
            .speaker {
                display: flex;
                flex-direction: column;
                .no-image,
                img {
                    border-radius: $border-radius;
                    aspect-ratio: 1;

                    &.no-image {
                        background-color: $dark-grey-op10;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                        svg {
                            width: 70%;
                            height: auto;
                        }
                    }
                }
                .speaker__name {
                    padding-top: 0.625rem;
                    font-size: 0.625rem;
                    line-height: 1rem;
                    font-weight: $fontweight-gu-bold;
                    color: $dark-grey;
                    text-align: center;
                }
            }
        }
    }
}

.eventbuizz-modal-body-grid-separator {
    grid-area: separator;
    width: 1px;
    background-color: $dark-grey-op10;
}

.eventbuizz-modal-body-grid-event-info {
    grid-area: event-info;
    margin-bottom: 1rem;

    .eventbuizz-modal-body-grid-event-info__details {
        .eventbuizz-modal__action {
            margin-top: 1.875rem;

            a {
                @include mix-button($color-gu-dark-grey, #fff);
                @include mix-button-is-slim;
                padding: 0.625em 3.18em !important;
            }
        }
    }

    .eventbuizz-modal-body-grid-event-info__contact {
        margin-top: 4rem;
        .contact-info {
            padding-top: 1rem;
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: $color-gu-fade-23; //Change this
        }
    }
}
