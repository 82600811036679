.radio-checkbox-group {
}

.radio-checkbox-group__title {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.33;
    color: $dark-grey;
    margin-bottom: 1rem;
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

.radio-checkbox {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    &:first-of-type {
        margin-top: 0;
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        height: 1.5rem;
        width: 1.5rem;
        border: 1px solid $input-border-color;
        border-radius: $border-radius;
        transition: 0.3s;
        transition-property: border-color;
        flex-shrink: 0;

        &:hover {
            cursor: pointer;
            border-color: $color-gu-blue-dusty;
        }

        &:checked {
            position: relative;

            border-color: $color-gu-blue-dusty;

            &::before {
                background-color: currentColor;
                position: absolute;
                left: 0;
                top: 9px;
                height: 6px;
                width: 2px;
                content: "";
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
                border-radius: 2px 2px 0px 0px;
            }

            &::after {
                background-color: currentColor;
                position: absolute;
                left: 0;
                top: 13px;
                height: 2px;
                width: 10px;
                content: "";
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
                border-radius: 0px 2px 2px 0px;
            }
        }
    }

    label {
        font-size: 0.75rem;
        line-height: 1.65;
        margin-left: 1rem;
        cursor: pointer;
        color: $black;

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
}
