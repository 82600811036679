@use "sass:math";

@mixin mix-theme-trumpet() {
    font-size: 12px;
    text-transform: uppercase;

    //GU
    @include themer($theme-gyldendal) {
        color: $color-gu-orange;
        font-weight: $fontweight-gu-bold;
    }
    //munk
    @include themer($theme-munksgaard) {
        color: $color-mg-blue;
        font-weight: $fontweight-mg-bold;
    }

    //hans
    @include themer($theme-hansreitzel) {
        color: $color-hr-red;
        font-weight: $fontweight-hr-din-bold;
    }
}

@mixin mix-theme-like-header() {
    @include mix-theme-headline-color();
    font-family: $header-font-family;
    line-height: 1.2em;
    //GU
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-light;
    }
    //munk
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-light;
    }
    //hans
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-regular;
    }
}

@mixin mix-theme-like-h1() {
    @include mix-theme-like-header();
    font-size: 42px;

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }
    //munk
    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
    //hans
    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-semibold;
    }

    //Breakpoints
    @include mix-content-max-width($breakpoint-w-cat) {
        font-size: 32px;
    }
}

@mixin mix-theme-like-h2() {
    @include mix-theme-like-header();
    font-size: 2rem;

    //Breakpoints
    @include mix-content-max-width($breakpoint-w-cat) {
        font-size: 1.5rem;
    }

    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
        line-height: 2rem;
        @include mix-content-max-width($breakpoint-w-cat) {
            line-height: 1.75rem;
        }
    }

    //munk
    @include themer($theme-munksgaard) {
        line-height: 2.5rem;
        font-weight: $fontweight-mg-bold;
        @include mix-content-max-width($breakpoint-w-cat) {
            line-height: 1.75rem;
        }
    }
    //hans
    @include themer($theme-hansreitzel) {
        line-height: 2.5rem;
        font-weight: $fontweight-hr-semibold;
        @include mix-content-max-width($breakpoint-w-cat) {
            line-height: 1.5rem;
        }
    }
}

@mixin mix-theme-like-h3() {
    @include mix-theme-headline-color();
    font-family: $header-font-family;

    //GU
    @include themer($theme-gyldendal) {
        font-size: 16px;
        font-weight: $fontweight-gu-bold;
    }
    //munk
    @include themer($theme-munksgaard) {
        font-size: 20px;
        font-weight: $fontweight-mg-semibold;
    }
    //hans
    @include themer($theme-hansreitzel) {
        font-size: 20px;
        font-weight: $fontweight-hr-din-bold;
    }
}

@mixin mix-theme-like-h4() {
    @include mix-theme-headline-color();
    font-family: $header-font-family;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: $fontweight-gu-bold;
}

@mixin mix-theme-like-h5() {
    @include mix-theme-headline-color();
    font-family: $header-font-family;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: $fontweight-gu-medium;
}

@mixin mix-theme-like-h6() {
    @include mix-theme-headline-color();
    font-family: $header-font-family;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: $fontweight-gu-bold;
}

@mixin mix-gu-header-styling() {
    color: #fff;
    text-transform: uppercase;
    margin: 0 0.25em;
    line-height: 1.3em;
    font-weight: 400;

    // styling for black background
    span {
        @include mix-gu-header-span();
        word-wrap: break-word;
    }
}

@mixin mix-theme-like-a() {
    @include mix-theme-link-color();
    text-decoration: none;

    //hans
    @include themer($theme-hansreitzel) {
        text-decoration: underline;
    }
}

@mixin mix-rich-text-markup() {
    a {
        @include mix-theme-like-a;
    }

    ul,
    ol {
        display: block;
        list-style-type: none;
        margin: 1em 0;
        padding-left: 2em;

        li {
            position: relative;

            &:before {
                z-index: 2;
                position: absolute;
                font-size: 1rem;
                left: -1.7em;
                width: 1.6em;
                display: block;
                height: 1.4em;
                line-height: 1.6em;
                text-align: center;
                color: #fff;
                border-radius: 50%;
            }
        }
    }
    ul {
        li {
            &:before {
                content: "";
                transform: scale(0.25);
                top: 0em;
                background-color: $color-gu-orange;

                @include themer($theme-hansreitzel) {
                    background-color: $color-hr-red;
                }
                @include themer($theme-munksgaard) {
                    background-color: $color-mg-blue;
                }
            }
        }
    }
    ol {
        counter-reset: li;

        li {
            &:before {
                top: -0.05em;
                margin-left: 0.3em;
                content: counter(li) ".";
                counter-increment: li;
                font-weight: $fontweight-gu-medium;
                color: $color-gu-orange;

                @include themer($theme-hansreitzel) {
                    color: $color-hr-red;
                }
                @include themer($theme-munksgaard) {
                    color: $color-mg-blue;
                }
            }
        }
    }

    li + li {
        margin-top: 0.5em;
    }

    p {
        margin: 1em 0;
    }
    img {
        margin-top: 0.8em;
    }
    h1,
    h2 {
        @include mix-theme-like-header();
        @include mix-theme-headline-color();
        @include mix-theme-like-h2();
        margin-bottom: math.div($gutter, 2);
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        @include mix-theme-like-header();
        @include mix-theme-like-h2();
        font-size: 18px;
        margin-bottom: math.div($gutter, 2);
    }
    hr {
        margin: 1.5em 0;
    }

    img {
        height: auto !important;
    }

    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }
}

@mixin mix-theme-regular-bodyfont() {
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-book;
    }

    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-normal;
    }

    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-regular;
    }
}

@mixin mix-theme-medium-bodyfont() {
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-medium;
    }

    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-medium;
    }

    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-semibold;
    }
}

@mixin mix-theme-bold-bodyfont() {
    @include themer($theme-gyldendal) {
        font-weight: $fontweight-gu-bold;
    }

    @include themer($theme-hansreitzel) {
        font-weight: $fontweight-hr-din-bold;
    }

    @include themer($theme-munksgaard) {
        font-weight: $fontweight-mg-bold;
    }
}

@mixin mix-theme-manchet-text() {
    @include mix-theme-paragraph-color();
    font-size: 20px;
    font-weight: 400;
}

@mixin mix-theme-subline-text() {
    font-size: 16px;
    font-weight: 400;

    //munk
    @include themer($theme-munksgaard) {
        color: $color-mg-blue-dusty-light;
    }
    //hans
    @include themer($theme-hansreitzel) {
        color: $color-hr-red;
        padding-left: 30px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0.7em;
            width: 25px;
            border-bottom: 1px solid $color-hr-red;
        }
    }
}
@mixin mix-theme-authors-wrapper() {
    font-family: $body-font-family;
    @include mix-theme-prefix-text("FORFATTERE");
}
@mixin mix-theme-editors-wrapper() {
    font-family: $body-font-family;
    @include mix-theme-prefix-text("REDAKTØRER");
}
@mixin mix-theme-prefix-text($content: "") {
    position: relative;

    &:before {
        content: $content;
        font-weight: $fontweight-gu-bold;
        font-size: 12px;
        position: absolute;
        top: 4px;
        left: 0;
    }
    //gu
    @include themer($theme-gyldendal) {
        padding-left: 100px;

        &:before {
            color: $black;
        }
    }
    //munk
    @include themer($theme-munksgaard) {
        padding-left: 90px;

        &:before {
            color: $color-mg-blue-dusty;
        }
    }
    //hans
    @include themer($theme-hansreitzel) {
        padding-left: 90px;

        &:before {
            color: $black;
        }
    }
}

@mixin mix-theme-label-text() {
    @include mix-theme-label-color;
    font-size: 14px;
    font-weight: 400;
}

@mixin mix-theme-notification-text($type) {
    font-size: 14px;
    font-weight: 400;

    @if ($type == "info") {
        color: $color-gu-orange;
    } @else if($type == "alert") {
        color: $color-gu-red;

        @include themer($theme-munksgaard, $theme-hansreitzel) {
            color: $color-error;
        }
    }
}

@mixin mix-theme-discounted-old-price() {
    @include mix-theme-paragraph-color;
    position: relative;
    text-decoration: line-through;
}

@mixin mix-theme-discounted-new-price() {
    font-weight: 600;
    color: $color-gu-green;

    @include themer($theme-munksgaard) {
        color: $color-mg-green-dark;
    }
}

@mixin mix-theme-active-tab() {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -6px;
        display: block;
        border-radius: 50px;
    }

    //GU
    @include themer($theme-gyldendal) {
        color: $color-gu-orange; // Fallback color
        @include mix-subtheme-primary-color-props("color");

        &:after {
            background-color: $color-gu-orange; // Fallback color
            @include mix-subtheme-primary-color-props("background-color");
        }
    }
    //munk
    @include themer($theme-munksgaard) {
        color: $color-mg-petroleum-blue;

        &:after {
            background-color: $color-mg-petroleum-blue;
        }
    }
    //hans
    @include themer($theme-hansreitzel) {
        @include mix-theme-primary-color-props("color");

        &:after {
            @include mix-theme-primary-color-props("background-color");
        }
    }
}

@mixin mix-theme-overflow-fade-white($width: 30px) {
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: $width;
        background: rgba(255, 255, 255, 0);
        background: linear-gradient(to left, #fff 25%, rgba(255, 255, 255, 0));
    }
}
